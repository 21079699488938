/** @jsx jsx */
import { jsx } from "theme-ui"
import * as React from "react"
import { Link } from "gatsby"
import { AnchorButton } from "gatsby-interface"
import url from "url"
import { GatsbyImage } from "gatsby-plugin-image"
import qs from "qs"

import Modal from "../../components/modal"
import ShareMenu from "../../components/share-menu"
import Screenshot from "../shared/screenshot"

import FeaturedSitesIcon from "../../assets/icons/featured-sites"
import { MdArrowUpward, MdLink } from "react-icons/md"
import { GoMarkGithub as GithubIcon } from "react-icons/go"

const gutter = 7
const gutterDesktop = 9

const styles = {
  link: {
    color: `link.color`,
    textDecoration: `none`,
  },
  prevNextLink: {
    color: `lilac`,
    fontFamily: `heading`,
    px: [7, null, null, 0],
  },
  prevNextLinkSiteTitle: {
    color: `link.color`,
    fontWeight: `bold`,
  },
  prevNextImage: {
    borderRadius: 1,
    boxShadow: `overlay`,
  },
}

const cleanUrl = mainUrl => {
  const parsed = url.parse(mainUrl)
  let path = parsed.pathname
  if (path[path.length - 1] === `/`) path = path.slice(0, path.length - 1)
  return parsed.hostname + path
}

const Featured = () => (
  <div
    sx={{
      color: `text.secondary`,
      display: `flex`,
      fontWeight: `bold`,
      fontFamily: `heading`,
      mr: 5,
    }}
  >
    <span
      sx={{
        height: t => t.space[6],
        m: 0,
        mr: 3,
        width: t => t.space[6],
      }}
    >
      <FeaturedSitesIcon />
    </span>
    {` `}
    Featured
  </div>
)

const SourceLink = ({ ...props }) => (
  <a
    {...props}
    sx={{
      "&&": {
        border: 0,
      },
      display: `flex`,
      alignItems: `center`,
      mr: 4,
      color: `link.color`,
      width: `100%`,
    }}
  >
    <GithubIcon sx={{ fontSize: 3, mr: 3, color: `link.color` }} />
    Source
  </a>
)

/**
 * @returns {string} - the URI that should be navigated to when the showcase details modal is closed
 */
function getExitLocation(path, filters = {}) {
  if (Object.keys(filters).length) {
    const queryString = qs.stringify({ filters })
    return `/showcase/?${queryString}`
  } else if (path.includes(`use-cases`)) {
    return `${path.match(/\/use-cases\/[^/]+/)[0]}/`
  } else {
    return `/showcase/`
  }
}

function ShowcaseModalWithPrevNext({
  children,
  location,
  prevSite: previousSite,
  nextSite,
}) {
  const { filters } = location.state || {}

  return (
    <Modal
      modalBackgroundPath={getExitLocation(location.pathname, filters)}
      next={
        nextSite
          ? `/showcase/` + cleanUrl(nextSite.showcaseSiteFields.mainUrl)
          : null
      }
      previous={
        previousSite
          ? `/showcase/` + cleanUrl(previousSite.showcaseSiteFields.mainUrl)
          : null
      }
      filters={filters}
      modalNextLink={
        nextSite && (
          <>
            <GatsbyImage
              key={nextSite.slug}
              sx={styles.prevNextImage}
              image={nextSite.showcaseSiteFields.screenshot.gatsbyImage}
              alt={`Screenshot of ${nextSite.showcaseSiteFields.title}`}
            />
            <div
              sx={t => ({
                ...styles.prevNextLink,
                [t.mediaQueries.tablet]: {
                  position: `absolute`,
                  top: 240,
                  width: 300,
                  transform: `translateX(-80px) rotate(90deg)`,
                },
              })}
            >
              <MdArrowUpward
                sx={t => ({
                  transform: `rotate(90deg)`,
                  [t.mediaQueries.tablet]: {
                    transform: `none`,
                  },
                })}
              />
              <div> Next Site in Showcase </div>
              <div sx={styles.prevNextLinkSiteTitle}>
                {nextSite.showcaseSiteFields.title}
              </div>
            </div>
          </>
        )
      }
      modalPreviousLink={
        previousSite && (
          <>
            <GatsbyImage
              key={previousSite.slug}
              sx={styles.prevNextImage}
              image={previousSite.showcaseSiteFields.screenshot.gatsbyImage}
              alt={`Screenshot of ${previousSite.showcaseSiteFields.title}`}
            />
            <div
              sx={t => ({
                ...styles.prevNextLink,
                [t.mediaQueries.tablet]: {
                  textAlign: `right`,
                  position: `absolute`,
                  top: 240,
                  width: 300,
                  transform: `translateX(-80px) rotate(-90deg)`,
                },
              })}
            >
              <MdArrowUpward
                sx={t => ({
                  transform: `rotate(-90deg)`,
                  [t.mediaQueries.tablet]: {
                    transform: `none`,
                  },
                })}
              />
              <div> Previous Site in Showcase </div>
              <div sx={styles.prevNextLinkSiteTitle}>
                {previousSite.showcaseSiteFields.title}
              </div>
            </div>
          </>
        )
      }
    >
      {children}
    </Modal>
  )
}

function ShowcaseModalWithoutPrevNext({ children, location }) {
  const { filters } = location.state || {}

  return (
    <Modal
      modalBackgroundPath={getExitLocation(location.pathname, filters)}
      next={null}
      previous={null}
      filters={filters}
    >
      {children}
    </Modal>
  )
}

function ShowcaseModal({
  children,
  location,
  isModal,
  hidePrevNext,
  nextSite,
  prevSite,
}) {
  if (!isModal) return children

  if (hidePrevNext) {
    return (
      <ShowcaseModalWithoutPrevNext location={location}>
        {children}
      </ShowcaseModalWithoutPrevNext>
    )
  }

  return (
    <ShowcaseModalWithPrevNext
      location={location}
      nextSite={nextSite}
      prevSite={prevSite}
    >
      {children}
    </ShowcaseModalWithPrevNext>
  )
}

const ShowcaseDetails = ({
  location,
  site,
  isModal,
  hidePrevNext,
  nextSite,
  prevSite,
}) => {
  const screenshotFile = site.showcaseSiteFields.screenshot

  return (
    <ShowcaseModal
      isModal={isModal}
      location={location}
      hidePrevNext={hidePrevNext}
      nextSite={nextSite}
      prevSite={prevSite}
    >
      <div
        sx={{
          display: `flex`,
          flexDirection: `column`,
          maxWidth: isModal ? false : 1080,
          margin: isModal ? false : `0 auto`,
          width: `100%`,
          order: 1,
          zIndex: 20,
        }}
      >
        <div css={{ width: `100%` }}>
          <div
            sx={t => ({
              p: gutter,
              [t.mediaQueries.desktop]: {
                p: gutterDesktop,
                pb: gutter,
                pr: isModal ? 96 : false,
              },
            })}
          >
            <h1 sx={{ m: 0, color: `inherit` }}>{site.title}</h1>
            <a href={site.showcaseSiteFields.mainUrl} sx={styles.link}>
              {cleanUrl(site.showcaseSiteFields.mainUrl)}
            </a>
            {site.showcaseSiteFields.builtBy && (
              <span sx={{ color: `text.secondary` }}>
                <span sx={{ px: 3 }}>/</span>
                Built by {` `}
                {site.showcaseSiteFields.builtByUrl ? (
                  <a
                    href={site.showcaseSiteFields.builtByUrl}
                    sx={{ ...styles.link }}
                  >
                    {site.showcaseSiteFields.builtBy}
                  </a>
                ) : (
                  site.showcaseSiteFields.builtBy
                )}
              </span>
            )}
          </div>
          <div
            sx={t => ({
              alignItems: `center`,
              display: `flex`,
              fontFamily: `header`,
              mx: gutter,
              py: 5,
              [t.mediaQueries.desktop]: {
                mx: gutterDesktop,
              },
            })}
          >
            {site.categories.nodes.find(
              category => category.name === `Featured`
            ) && <Featured />}
            {site.showcaseSiteFields.sourceUrl && (
              <SourceLink href={site.showcaseSiteFields.sourceUrl} />
            )}
            <div
              sx={{
                alignSelf: `center`,
                ml: `auto`,
              }}
            >
              <div
                css={{
                  display: `flex`,
                  position: `relative`,
                  zIndex: 1,
                }}
              >
                <AnchorButton
                  rightIcon={<MdLink />}
                  sx={{ mr: 3 }}
                  href={site.showcaseSiteFields.mainUrl}
                  size="M"
                >
                  Visit site
                </AnchorButton>
                <ShareMenu
                  image={screenshotFile.resize.src}
                  title={`The ${site.title} website looks fantastic!`}
                  url={`https://gatsbyjs.com${location.pathname}`}
                />
              </div>
            </div>
          </div>
          <Screenshot
            alt={`Screenshot of ${site.title}`}
            boxShadow={!isModal}
            imageSharp={screenshotFile.gatsbyImage}
            key={site.id}
          />
          <div
            sx={t => ({
              p: gutter,
              [t.mediaQueries.desktop]: { p: gutterDesktop },
            })}
          >
            <p>{site.showcaseSiteFields.description}</p>
            <div sx={{ display: `flex` }}>
              <div sx={{ color: `text.secondary`, pr: 6 }}>Categories</div>
              <div>
                {site.categories.nodes.map((c, i) => (
                  <React.Fragment key={c.id}>
                    <Link
                      to={`/showcase/?${qs.stringify({ filters: [c.name] })}`}
                      state={{ isModal: true }}
                      sx={styles.link}
                    >
                      {c.name}
                    </Link>
                    {i === site.categories.nodes.length - 1 ? `` : `, `}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ShowcaseModal>
  )
}

export default ShowcaseDetails
