import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../layout"
import SiteHead from "../../head"
import { SkipNavTarget } from "../../components/shared/components/SkipNav"
import ShowcaseDetails from "./showcase-details"

export function Head(props) {
  const site = props.data.site
  const description = `Gatsby showcase site build by ${site?.showcaseSiteFields?.builtBy}`

  return <SiteHead {...props} title={site?.title} description={description} />
}

export default function ShowcaseTemplate({
  data: { site, nextSite, prevSite },
  location,
  pageContext: { hidePrevNext },
}) {
  const isModal =
    location.state && location.state.isModal && window.innerWidth > 750

  return (
    <Layout pathname={location.pathname}>
      <main>
        <SkipNavTarget />
        <ShowcaseDetails
          isModal={isModal}
          site={site}
          nextSite={nextSite}
          prevSite={prevSite}
          location={location}
          hidePrevNext={hidePrevNext}
        />
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ShowcaseDetails($id: String!, $prev: String, $next: String) {
    site: wpShowcaseSite(id: { eq: $id }) {
      id
      slug
      title
      categories {
        nodes {
          id
          name
        }
      }
      showcaseSiteFields {
        mainUrl
        builtBy
        builtByUrl
        description
        screenshot {
          gatsbyImage(
            layout: CONSTRAINED
            width: 750
            outputPixelDensities: [1]
          )
          resize(width: 1500, height: 1500, format: JPG, cropFocus: CENTER) {
            src
          }
        }
        sourceUrl
      }
    }

    prevSite: wpShowcaseSite(id: { eq: $prev }) {
      slug
      showcaseSiteFields {
        title
        mainUrl
        screenshot {
          gatsbyImage(
            layout: FIXED
            width: 100
            height: 100
            outputPixelDensities: [1]
          )
        }
      }
    }

    nextSite: wpShowcaseSite(id: { eq: $next }) {
      slug
      showcaseSiteFields {
        title
        mainUrl
        screenshot {
          gatsbyImage(
            layout: FIXED
            width: 100
            height: 100
            outputPixelDensities: [1]
          )
        }
      }
    }
  }
`
