import * as React from "react"

function FeaturedSitesIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        d="M10.875 18.875L9 23l-1.875-4.125L3 17l4.125-1.875L9 11l1.875 4.125L15 17l-4.125 1.875zm8.378-6.622L18 15l-1.253-2.747L14 11l2.747-1.253L18 7l1.253 2.747L22 11l-2.747 1.253zM11.94 5.94L11 8l-.94-2.06L8 5l2.06-.94L11 2l.94 2.06L14 5l-2.06.94z"
        fill="currentColor"
      />
    </svg>
  )
}

export default FeaturedSitesIcon
